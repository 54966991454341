export function getRefundTooltip(item, { ignoreSource = false } = {}) {
  if (item.dispute_status) {
    return `Dispute Status: ${
      item.dispute_status
    }. Dispute Reason: ${item.dispute_reason ??
      'N/A'}. Dispute Ref: ${item.dispute_reference ?? 'N/A'}`
  }

  if (item.refund_failure_reason) {
    return `Failure Reason: ${item.refund_failure_reason ||
      '--'}. Refund Status: ${item.refund_status || 'N/A'}.
        Refund Ref: ${item.refund_reference || 'N/A'}.`
  }

  if (item.is_refunded) {
    return `Already refunded. Refund Status: ${item.refund_status || 'N/A'}.
        Refund Ref: ${item.refund_reference || 'N/A'}.`
  }

  if (!ignoreSource && !item.is_source_refundable) {
    return 'Source is not refundable'
  }

  return 'Click to refund'
}
